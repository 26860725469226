import React, { useState } from "react"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import SiteLogo from "../images/site-logo.png"
import SiteLogoDark from "../images/site-logo-dark.png"
import { Link, navigate } from "gatsby"
import { ThemeToggler } from 'gatsby-plugin-dark-mode'

const Menu = ({setSearchQuery}) => {
    const [query, setQuery] = useState('')
    const [appTheme, setAppTheme] = useState("")

    const handleSubmit = event => {
        event.preventDefault()
        if(setSearchQuery){
            navigate('/search/')
            setSearchQuery(query)
        }else{
            navigate('/search/', { state: {query:query}})
        }
        
    }
    const handleInput = event => {
        setQuery(event.target.value)
      }

    return (
        <Navbar  expand="lg" id ="menu-bar">
            <Navbar.Brand href="">
                <Link to="/">
                <img
                src={appTheme === "dark"? SiteLogoDark : SiteLogo}
                width="100"
                className="d-inline-block align-top"
                alt="Site Logo"
                />
                </Link>
            </Navbar.Brand>
            <ThemeToggler>
                {({ theme, toggleTheme }) => {
                    setAppTheme(theme)
                    return(
                        <Button variant="primary"
                        onClick={(e) => {
                            toggleTheme(theme === "dark"? "light" : "dark")
                            setAppTheme(theme === "dark"? "light" : "dark")
                            }}>
                            <i class="fas fa-adjust"></i>
                        </Button>
                )}}
            </ThemeToggler>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                <Nav.Link><Link to="/">📖Leer+</Link></Nav.Link>
                <Nav.Link><Link to="/historical">Archivo</Link></Nav.Link>
                
                <NavDropdown title="Categorías" id="basic-nav-dropdown">
                <NavDropdown.Item><Link to="/categories/novela">Novelas</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/categories/cuento">Cuentos</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/categories/fanfiction">Fanfiction</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/categories/poesia">Poesía</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/categories/noficcion">No ficción</Link></NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Números" id="basic-nav-dropdown">
                    <NavDropdown.Item><Link to="/tags/numero-17">Farol 17</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/tags/numero-16">Farol 16</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/tags/numero-15">Farol 15</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/tags/numero-14">Farol 14</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/tags/numero-13">Farol 13</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/tags/numero-12">Farol 12</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/tags/numero-11">Farol 11</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/tags/numero-10">Farol 10</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/tags/numero-9">Farol 9</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/tags/numero-8">Farol 8</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/tags/numero-7">Farol 7</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/tags/numero-6">Farol 6</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/tags/numero-5">Farol 5</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/tags/numero-4">Farol 4</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/tags/numero-3">Farol 3</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/tags/numero-2">Farol 2</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/tags/numero-1">Farol 1</Link></NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Más" id="basic-nav-dropdown">
                    <NavDropdown.Item><Link to="/pages/publica-aqui">Publica aquí</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/pages/suscribete">Suscríbete</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/pages/donar">Donar</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/pages/mapa-de-fickers-en-espanol">Mapa de Fickers en Español</Link></NavDropdown.Item>
                    <NavDropdown.Item><Link to="/pages/premio-cuento-2023">Premio Cuento Corto "Autor Colectivo" 2023</Link></NavDropdown.Item>
                </NavDropdown>
                </Nav>
                <Form inline onSubmit={handleSubmit}>
                <FormControl type="text" placeholder="Buscar" className="mr-sm-2" id="menu-search-form" value={query} onChange={handleInput} />
                <Button variant="outline-primary" type="submit">Buscar</Button>
                </Form>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Menu
